import React from "react";
import Footer from "../components/Footer";
import "../styles/About.css";

const About = () => {
	return (
		<div className="about-container">
			<h1 className="about-title">
				About Albi<span className="trademark">™</span> Handheld Adapters
				<span className="trademark">™</span>
			</h1>
			<p className="about-description">
				Language Adapters were created with the vision to make Latin and Greek accessible,
				fun, and meaningful for learners of all ages. The Albi (pronounced al-bee) gives a
				student a powerful start into a language where word roots host hundreds of possible
				endings. It condenses a dozen grammar tables into six handy sticks. Similarities
				between lists spring clearly into view, so that students can adapt themselves to
				the language more quickly, without getting lost.
			</p>

			<section className="about-section">
				<h3 className="section-title">Using an Albi</h3>
				<p className="about-description">
					The endings appear against the left edge of the tool. Hold it between your eye
					and a word root in a book, on a screen, or on a worksheet, to help you choose
					the right ending for the word you want.
				</p>

				<p className="about-description">
					Use an Albi to build a word by hand:
					<ol>
						<li>Choose the right stick</li>
						<li>Find the correct ending</li>
						<li>Hold it up to the root to see the finished word</li>
					</ol>
				</p>
			</section>

			<section className="about-section">
				<div className="bio-section">
					<h2 className="section-title">Meet Jean Albert</h2>

					<div className="bio-content">
						<div className="bio-image">
							<img src="/AdlerAndAlbis.jpg" alt="Early Albi Sticks" />
						</div>
						<div className="bio-text">
							<p>
								In the beginning, Mrs. Albert hand-printed Latin endings onto popsicle sticks,
								making a hands-on, fun device, to be mused over and fiddled with. Today's Albi
								is printed in color on a rugged, durable plastic.
							</p>
							<p>
								Albi Stix is a testament to Jean’s lifelong curiosity and love of ancient
								languages. What began as a simple idea to make learning Latin easier and more
								fun for kids has transformed into a powerful tool for introducing inflected
								lanugages.
							</p>
						</div>
					</div>

					<div className="bio-content">
						<div className="bio-image">
							<img src="/jeans_family.png" alt="Albistix" />
						</div>
						<div className="bio-text">
							<p>
								Encouraged by her father, Jean studied languages with passion, majoring in
								German at Wheaton College in Illinois.
							</p>
							<p>
								She homeschooled a family of nine kids and currently directs her youngest
								daughter's <a href="https://info.classicalconversations.com/cc-main-landing-page">
									Classical Conversations</a> class. She tutors Latin and German, and  loves to
								explore New Testament Greek, languages, and etymology.
							</p>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default About;
