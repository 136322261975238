import React, { useRef, useState, useEffect } from 'react';
import '../styles/Carousel.css';

const Carousel = ({ testimonials }) => {
	const carouselRef = useRef(null);
	const [haveFocus, setHaveFocus] = useState(false);

	useEffect(() => {
		console.log(``);
		document.body.style.overflow = haveFocus ? 'hidden' : 'auto';
	}, [haveFocus]);

	const handleScroll = e => {
		e.preventDefault();
		const scrollAmount = e.deltaY < 0 ? -200 : 200;
		carouselRef.current.scrollBy({
			left: scrollAmount,
			behavior: 'smooth'
		});
	};

	return (
		<div 
				className="carousel-container" 
				onWheel={handleScroll} ref={carouselRef}
				onMouseEnter={() => setHaveFocus(true)}
				onMouseLeave={() => setHaveFocus(false)}>
			<div className="carousel">
				{testimonials.map((testimonial, index) => (
					<div className='cell'>
						<blockquote>
							{testimonial.testimonial}
							<span>{`- ${testimonial.name}`}</span>
						</blockquote>
					</div>
				))}
			</div>
		</div>
	)
};

export default Carousel;
