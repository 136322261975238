import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Tutorials from './pages/Tutorials';
import Order from './pages/Order';
import './App.css';

const App = () => {
	const [navbarOpen, setNavbarOpen] = useState(false);

	const toggleNavbar = () => {
		setNavbarOpen(!navbarOpen);
	};

	return (
		<Router>
			<div>
				<div className="hamburger-container" onClick={toggleNavbar}>
					<div className="hamburger-icon">
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
					</div>
				</div>

				<nav className={`navbar ${navbarOpen ? "open" : ""}`}>
					<div className="navbar-container">
						<Link to="/" className="nav-link" onClick={() => setNavbarOpen(false)}>Home</Link>
						<Link to="/products" className="nav-link" onClick={() => setNavbarOpen(false)}>Products</Link>
						<Link to="/tutorials" className="nav-link" onClick={() => setNavbarOpen(false)}>Tutorials</Link>
						<Link to="/order" className="nav-link" onClick={() => setNavbarOpen(false)}>Order</Link>
						<Link to="/about" className="nav-link" onClick={() => setNavbarOpen(false)}>About</Link>
					</div>
				</nav>

				<div className="content">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/products" element={<Products />} />
						<Route path="/tutorials" element={<Tutorials />} />
						<Route path="/order" element={<Order />} />
						<Route path="/about" element={<About />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
};

export default App;
