import React, { useState } from "react";
import Footer from "../components/Footer";
import "../styles/Products.css";

const Products = () => {
	const products = [
		{
			title: "Latin Nouns and Adjectives Adapter",
			description: `This handy tool fits the right one of seventy possible Latin endings onto 
				a Noun or Adjective stem. Zip through a drill set orally, and cut written drill time 
				dramatically. Strengthen and speed up your familiarity with Latin forms. This Albi 
				tackles ALL standard Adjective AND Adverb endings, including Comparative and 
				Superlative, and even the elusive 6th Declension. Enjoy your drills and leave more 
				time for translation work.`,
			thumbnail: "/LatinNouns.jpg",
		},
		{
			title: "Latin Verbs Adapter",
			description: `This elegant Albi spans Latin Present-Stem endings for Present, Imperfect, 
				and Future Tenses, Indicative and Subjunctive. Stem Vowels are displayed for each 
				conjugation as it adapts to each Tense and Mood. Use one and see just how far "-o -s 
				-t, -mus -tis -nt" can take you. See where Future endings "bo, bis, bit" need a boost 
				for Conjugations 3 & 4, and also for -io Verbs. This set also features one Stick to 
				"Passify" your Active Verbs.`,
				thumbnail: "/LatinVerbs.jpg",
			},
		{
			title: "Latin Passive ParaphraStix",
			note: "Coming Soon",
			description: `Add a modest set of SUM Stix to your 1st and 2nd Declension Nouns, and 
				Viola! Perfect Passive construction complete.`,
			thumbnail: "/favicon.jpg",
		},
		{
			title: "Latin SUM Stix",
			note: "Coming Soon",
			description: `Although some languages manage just fine without the Verb "to be," Latin 
				(and Greek) have plenty of forms of "sum", aka "to be, each with distinct meanings: 
				"She may be, she might be, she will have been," et cetera. Don't get stuck! Enjoy 60 
				forms of "to be" in one orderly set. This clever arrangement helps you spy out trouble 
				spots, and march on toward mastery!`,
			thumbnail: "/favicon.jpg",
		},
		{
			title: "Latin Primary and Secondary Sequence!",
			note: "Coming Soon",
			description: `Arm yourself with this Tense Sequence set, and catch on to complex 
				sentences. Caesar's storytelling is full of them, for example!`,
			thumbnail: "/favicon.jpg",
		},
		{
			title: "The Perfect Latin Albi",
			note: "Coming Soon",
			description: `Perfect Tense in Latin could not be simpler when you combine the endings 
				on these Stix with a Perfect stem. Let the 3rd Principle Part take you on a Perfect 
				adventure.`,
			thumbnail: "/favicon.jpg",
		},
		{
			title: "Greek Nouns",
			note: "Coming Soon",
			description: `This triple set of Adapters is designed to enhance your recognition of 
				Nouns while translating from Greek to English. This is difficult since Greek has at 
				least 17 different Declensions! One Stick for each Case on every Set allows you to 
				spot the similarities of Plural Genitive, for example, across 5-7 Declensions at one 
				time.`,
			thumbnail: "/favicon.jpg",
		},
		{
			title: "Greek Verbs",
			description: `This beautiful Albi draws out Aspect, a poignant feature of Ancient Greek. 
				Learn and keep track of Progressive and Non-Progressive Aspect with this 6-Stick Set. 
				Introduce yourself at once to the personal endings of Present, Future, Imperfect, 
				Aorist, Perfect, and Pluperfect Tenses, and spy out the mechanics of forming Greek 
				Verbs. Treat yourself to a solid lineup of Active AND Passive endings. Get this broad 
				orientation from the get-go, before you get bogged down learning them all one at a 
				time.`,
			thumbnail: "/GreekVerbs.jpg",
		}
	];

	const [selectedIndex, setSelectedIndex] = useState(null);

	const toggleSelected = (index) => {
		setSelectedIndex(selectedIndex === index ? null : index);
	};

	return (
		<div className="products-container">
			<h1 className="products-title">
				Albi<span className="trademark">™</span> Handheld Adapters
				<span className="trademark">™</span> for Latin Grammar
			</h1>

			<h2 className="products-subtitle">
				Enhance your grammar with an Albi, click below for more information
			</h2>

			<ul className="products-list">
				{products.map((product, index) => (
					<li
						key={index}
						className={`product-item ${selectedIndex === index ? "selected" : ""}`}
						onClick={() => toggleSelected(index)}>
						<div className="product-content">
							<img src={product.thumbnail} alt={`${product.title} thumbnail`} />
							<div>
								<h2>{product.title}</h2>
								<p><strong><em>{product.note}</em></strong></p>
								<p id="product-description">{product.description}</p>
							</div>
						</div>
					</li>
				))}
			</ul>
			<Footer />
		</div>
	);
};

export default Products;
