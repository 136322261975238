import React, { useState } from "react";
import Footer from "../components/Footer";
import "../styles/Tutorials.css";

const Tutorials = () => {
	const tutorials = [
		{
			title: "An Introduction to the Latin Noun Adapter",
			description: `Your Latin Albi will help you put the right ending onto any noun.`,
			src: "https://www.youtube.com/embed/HXQGwUOXQEA"
		},
		{
			title: "Where to find Adjective and Adverb endings on your Latin Albi",
			description: `Your Latin Albi will help you generate adjectives and adverbs, 
				comparative and superlative. This video shows you where to find these endings.`,
			src: "https://www.youtube.com/embed/1nq26wQBG0Q"
		},
		{
			title: "Using your Latin Albi to generate Comparative and Superlative " +
				"Adjectives and Adverbs",
			description: `Your Latin Albi will help you generate adjectives and adverbs, 
				comparative and superlative. This video shows you where to find these endings.`,
			src: "https://www.youtube.com/embed/XMER_ln1Ck0"
		},
		{
			title: "Superlative Adverbs",
			description: `See how a green, long \u0113 will turn a superlative adjective 
				into a superlative adverb.`,
			src: "https://www.youtube.com/embed/YGsFze3ykrU"
		},
		{
			title: "Decline a 3rd Declension Noun with an Albi",
			description: `See how to isolate the stem and add the endings.`,
			src: "https://www.youtube.com/embed/xeJ4YEYJkZU"
		},
		{
			title: "Is it -ium or -um? Using your Latin Albi to make the call",
			description: `Use one of the guides at the bottom of your Latin Noun Albi to generate 
				the elusive genitive plural`,
			src: "https://www.youtube.com/embed/pPTvkx1bI5Q"
		}
	];

	const [selectedIndex, setSelectedIndex] = useState(null);
	const toggleSelected = index => {
		setSelectedIndex(selectedIndex === index ? null : index);
	}

	/* from youtube embed iframe */
	const allow = `accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
		picture-in-picture; web-share`;
	const referrerpolicy = "strict-origin-when-cross-origin"

	return (
		<div className="tutorials-container">
			<h1 className="tutorials-title">Albi Tutorials</h1>
			<h2 className="tutorials-subtitle">Explore the Albi Adapters with these tutorials, 
				click below</h2>
			<ul className="tutorials-list">
				{tutorials.map((tutorial, index) => (
					<li className={`tutorial-item ${selectedIndex === index ? "selected" : ""}`}
						onClick={() => toggleSelected(index)}>
						<h2>{tutorial.title}</h2>
						<div className="tutorial-container">
							<p>{tutorial.description}</p>
							<div className="video-container">
								<iframe
									title={tutorial.title}
									src={tutorial.src}
									allow={allow}
									referrerPolicy={referrerpolicy}
									allowFullScreen />
							</div>
						</div>
					</li>
				))}
			</ul>
			<Footer />
		</div>
	);
};

export default Tutorials;
